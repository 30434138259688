<template>
    <div class="sentry-container sentry-custom container mx-auto h-screen items-center">
        <div class="product-image p-10">
            <div class="product-image-wrapper" ref="productImage">
                <h1 class="product-title">Sentry</h1>
                <div class="product-showcase--wrapper">
                    <img src="~/assets/img/sentry-checkout-image.svg" class="product-image-element">
                </div>
                <img src="~/assets/img/disclaimer.svg" class="product-disclaimer">
            </div>
        </div>
        <div class="product-offers">
            <div class="sentry-header">
                <div class="sentry-inner-container">
                    <div class="sentry-product-preview">
                        <h1 class="product-title">Sentry</h1>
                        <img src="~/assets/img/device.png" class="product-preview">
                    </div>
                    <h1 class="sentry-title">Choose your plan</h1>
                    <div class="sentry-content ">
                        <div class="sentry-list">
                            <div class="sentry-item" :class="{ active: plan === 'annual' }" @click="setPlan('annual')">
                                <div class="sentry-item-promo">
                                    <div class="sentry-item-save">
                                        Save<br>{{ ANNUAL_PERCENT_SAVE }}%
                                    </div>
                                </div>
                                <div class="sentry-item-title">Annual</div>
                                <div class="sentry-item-subtitle"><b>${{ ANNUAL_PER_MONTH_PRICE }}/month</b><br>per sensor</div>
                                <div class="sentry-item-description">Billed as ${{ ANNUAL_PRICE }}/year </div>
                            </div>
                            <div class="sentry-item " :class="{ active: plan === 'monthly' }" @click="setPlan('monthly')">
                                <div class="sentry-item-title">Monthly</div>
                                <div class="sentry-item-subtitle"><b>${{ MONTHLY_PRICE }}/month</b><br>per sensor</div>
                                <div class="sentry-item-description">12 month minimum</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sentry-body">
                <div class="sentry-inner-container">

                    <div class="sentry-quantity mt-5">
                        <div class="sentry-quantity-title">How many Sentry devices?</div>
                        <div class="sentry-quantity-description">For optimal monitoring coverage, we recommend placing one Sentry device in each room you wish to monitor. (Example: 1 device per bedroom or living room)</div>

                        <div class="sentry-slider-wrapper">
                            <div class="sentry-quantity-component">
                                <button class="sentry-quantity-field-minus" @click.prevent="addQuantity(-1)">-</button>
                                <input type="text" class="sentry-quantity-field" v-model.number.lazy="quantity" :min="1" :max="199" maxlength="3" @change="checkQuantity()" @keypress="isNumber($event)">
                                <button class="sentry-quantity-field-plus" @click.prevent="addQuantity(1)">+
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="sentry-email mt-5" style="margin-bottom: 1rem">
                        <h1 class="sentry-title">Email address</h1>
                        <p class="sentry-subtitle">This email will be used for subscription & Sentry account</p>
                        <div class="sentry-field">
                            <input type="email" placeholder="Your email" v-model="email" :class="{ filled: email, invalid: email && !isEmailValid }" @change.lazy="onEmailUpdate">
                        </div>
                    </div>
                    <hr>
                    <div class="sentry-summary">
                        <div class="sentry-summary-item">
                            <div class="sentry-summary-details">
                                <label>{{ quantity }} x {{ subscriptionName }}</label>
                                <template v-if="plan === 'annual'">
                                    <template v-if="tiered">
                                        <p>Calculated at $15/mo. for the first sensor and $10/mo. thereafter.</p>
                                    </template>
                                </template>
                            </div>
                            <div class="sentry-summary-subtotal">
                                <s v-if="quantity > 1 && plan === 'annual' && tiered">{{ money(totalMonthlyPrice) }}<br></s>
                                {{ money(totalSalePrice) }}/mo
                            </div>
                        </div>
                        <div class="sentry-summary-item">
                            <div class="sentry-summary-details">
                                <label>{{ quantity }} x Sentry Hardware</label>
                            </div>
                            <div class="sentry-summary-subtotal">
                                <template v-if="deviceFee === 0">Included</template>
                                <template v-else>{{ money(deviceFee) }}</template>
                            </div>
                        </div>
                        <div class="sentry-summary-item" v-if="promoApplied && hasDiscount">
                            <div class="sentry-summary-details" style="color: #ef787c; ">
                                <label style=" color: #ef787c; " v-text="discountName"></label>
                            </div>
                            <div class="sentry-summary-subtotal" style=" color: #ef787c; ">- {{ money(discountTotal) }}</div>
                        </div>
                        <div class="sentry-summary-item" style="gap: 0.5rem; align-items: center;">

                            <template v-if="showPromoCode">
                                <div class="sentry-promo">
                                    <div class="sentry-field" v-if="!promoApplied">
                                        <input type="text" placeholder="Promo Code" name="promo" v-model="promo" :class="{ filled: promo, invalid: !promoValid, disabled: promoApplied }" @change="onPromoChange()">
                                    </div>
                                    <div class="sentry-field" v-else>
                                        {{ promo }} <span v-if="!promoValid" class="text-rose-500">Code is
                                            invalid</span>
                                        <span v-else class="opacity-50">Code Applied</span>
                                    </div>

                                </div>
                                <div class="sentry-button" :class="{ hidden: promoApplied }">
                                    <a href="#" class="btn btn-default" :class="{ 'disabled': !hasPromo }" @click.prevent="applyCoupon">APPLY</a>
                                </div>
                            </template>
                            <template v-else>
                                <div class="sentry-promo">
                                    <a href="#" @click.prevent="togglePromoCode" class="sentry-link-text">Add Promo Code</a>
                                </div>
                            </template>

                            <template v-if="showPromoCode && promoApplied">
                                <div class="sentry-button text-right justify-between">

                                    <div class="sentry-links">
                                        <a href="#" @click.prevent="editPromoCode" class="sentry-link-text">Edit</a> / <a href="#" @click.prevent="removePromoCode" class="sentry-link-text">Remove</a>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="sentry-summary-total">
                            <div class="sentry-summary-savings">
                                <b>Total</b>
                                <span v-if="totalSavings > 0">Save {{ money(totalSavings) }}</span>
                            </div>
                            <div class="sentry-summary-amount">
                                <b>{{ money(total) }}</b>
                                <span>will be billed today</span>
                            </div>
                        </div>

                    </div>
                    <div class="sentry-buttons">

                        <button class="btn btn-full btn-blue btn-xs" @click="order" :class="{ disabled: !formValid }">CHECKOUT</button>
                        <div class="sentry-tnc">
                            <label>By checking out, you agree to the <a href="https://cdn.shopify.com/s/files/1/1413/8998/files/WYND_Website_Terms_Conditions_2f73a28d-b07f-43d9-b643-610af6fe8153.pdf?v=1673897150" target="_blank"> Terms & Conditions</a></label>
                        </div>
                        <div class="w-100 align-center sentry-disclaimer">

                        </div>

                        <div class="sentry-footer">
                            <p class="mb-5"> <b class="text-xl">When does my subscription start?</b><br><br>
                                Upon completion of checkout, you will be charged for your device and subscription method. However, the subscription will not start until either 30 days has passed since ordering or you pair the device. If you do not pair the device within 30 days, the subscription will automatically begin. Please reach out to <a href="mailto:support@hellowynd.com" class="underline text-teal-500">Customer Support</a> if you have any concerns.</p>

                            <img src="~/assets/img/disclaimer-dark.svg" class="product-disclaimer-dark">
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted } from 'vue';
import qs from 'qs';

import storage from '@comicrelief/session-storage-fallback';

const config = useRuntimeConfig();

const promoDetails = ref(null);

const MONTHLY_PRICE = 25;
const ANNUAL_PER_MONTH_PRICE = 15
const ANNUAL_PRICE = ANNUAL_PER_MONTH_PRICE * 12;
const ANNUAL_PERCENT_SAVE = 40;

const tiered = ref(false);
const plan = ref('annual');
const device = ref(null)
const quantity = ref(1);
const tnc = ref(true);
const currency = ref('$');
const email = ref('');
const emailPattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
const added_to_cart = ref(false);
const showPromoCode = ref(false);

const productImage = ref(null);

const removePromoCode = function () {
    promoApplied.value = false;
    promo.value = '';
    promoValid.value = true;
    showPromoCode.value = false;
    promoDetails.value = null;
}
const editPromoCode = function () {
    promoApplied.value = false;
    setTimeout(function () {
        document.querySelector('[name="promo"]').focus();
    }, 100);
}

const isEmailValid = computed(() => {
    return !(email.value.match(emailPattern) === null);
})

const formValid = computed(() => {
    // if (!device.value) return false;
    if (!tnc.value) return false;
    if (!isEmailValid.value) return false;
    if (total.value <= 0) return false;

    return true;
});

const applyCoupon = function () {
    promoApplied.value = false;

    try {
        $fetch(`/.netlify/functions/stripe-coupon-check`, {
            method: 'POST', body: qs.stringify({
                key: config.public.STRIPE_PRODUCT,
                promo: promo.value,
                email: email.value
            })
        }).then((res) => {
            if (typeof res === 'string') res = JSON.parse(res);

            promoApplied.value = true;
            if (res.status && res.status == 'valid') {
                promoValid.value = true;

                if (res.type === 'promotion_code' && res.promotion_code) {
                    promoDetails.value = res.promotion_code?.coupon;
                }

                if (res.type === 'coupon' && res.coupon) {
                    promoDetails.value = res.coupon;
                }

                onPromoApplied();
            } else {
                promoValid.value = false;
            }
        }).catch((error) => {
            console.error('Error:', error);
            promoApplied.value = true;
            if (
                error.response && error.response?._data && (
                    error.response?._data?.includes('No such coupon') ||
                    error.response?._data?.includes('No such promotion code')
                )
            ) {
                promoValid.value = false;
            }
        });
    } catch (error) {
        console.error('Error:', error);
        promoApplied.value = true;
        promoValid.value = false;
    }

}

const onPromoApplied = function () {
    if (config.public.IS_PROD && typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: "cart_coupon_apply",
            coupon: promo.value,
            discountValue: discountTotal.value
        });
    }

    if (config.public.IS_PROD && typeof fbq !== 'undefined') {
        fbq('trackCustom', 'cart_coupon_apply', {
            coupon: promo.value,
            discountValue: discountTotal.value
        });
    }
}

const togglePromoCode = function () {

    if (!showPromoCode.value) {
        if (config.public.IS_PROD && typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: "cart_coupon_expand",
            });
        }

        if (config.public.IS_PROD && typeof fbq !== 'undefined') {
            fbq('trackCustom', 'cart_coupon_expand');
        }
    }
    return showPromoCode.value = !showPromoCode.value;
}

const checkQuantity = function () {
    if (typeof quantity.value !== 'number') quantity.value = 1;
    if (quantity.value > 199) quantity.value = 199;
    if (quantity.value < 1) quantity.value = 1;
}

const addQuantity = function (count) {
    quantity.value += parseInt(count);
    checkQuantity();

    if (config.public.IS_PROD && typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: "cart_quantity_update",
            quantity: quantity.value
        });
    }

    if (config.public.IS_PROD && typeof fbq !== 'undefined') {
        fbq('trackCustom', 'cart_quantity_update', {
            quantity: quantity.value
        });
    }
}

const isNumber = function (evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
    } else {
        return true;
    }
}

const money = function (amount, decimals = 0) {
    if ((parseFloat(amount) % 1) !== 0) {
        decimals = 2;
    }
    return currency.value + formatMoney(parseFloat(amount).toFixed(decimals), decimals);
}

const subscriptionName = computed(() => {
    if (plan.value === 'monthly') return 'Sentry Monthly Subscription';
    return 'Sentry Annual Subscription';
});

const unitPrice = computed(() => {
    return plan.value === 'annual' ? ANNUAL_PER_MONTH_PRICE : MONTHLY_PRICE;
})

const itemPrice = computed(() => {
    return unitPrice.value * (plan.value === 'annual' ? 12 : 1);
})
const totalSalePrice = computed(() => {
    if (plan.value === 'monthly') return unitPrice.value * quantity.value;

    if (!tiered.value) return unitPrice.value * quantity.value;

    if (quantity.value < 1) return 0;
    return (unitPrice.value) + ((quantity.value - 1) * 10);
});

const totalMonthlyPrice = computed(() => {
    return MONTHLY_PRICE * quantity.value;
})

const totalAnnual = computed(() => {
    return totalSalePrice.value * 12;
});

const totalSavings = computed(() => {
    let savings = 0;

    if (plan.value === 'monthly') savings = 0;
    else savings = (12 * totalMonthlyPrice.value) - (12 * totalSalePrice.value);

    if (discountTotal.value > 0) {
        savings += discountTotal.value;
    }

    return savings;
})

const deviceFee = computed(() => {
    return 0;
})

const subTotal = computed(() => {
    if (plan.value === 'monthly') return totalSalePrice.value + deviceFee.value;
    return totalAnnual.value;
})

const discountName = computed(() => {

    if (discountTotal.value > 0) {
        if (promoDetails.value) {
            if (promoDetails.value.amount_off) return 'Less';
            if (promoDetails.value.percent_off) return `Less ${promoDetails.value.percent_off}% Off`;
        }
    }

    return false;
});

const discountTotal = computed(() => {

    if (promoDetails.value) {
        if (promoDetails.value.amount_off) return promoDetails.value.amount_off / 100;
        if (promoDetails.value.percent_off) return (promoDetails.value.percent_off / 100) * subTotal.value;
    }

    return 0;
})

const total = computed(() => {
    return subTotal.value - discountTotal.value;
})

const sendGoogleEvent = function (data) {

    return new Promise((resolve, reject) => {

        sessionStorage.setItem('sentry-checkout-data', JSON.stringify(checkoutData.value));

        if (config.public.IS_PROD && typeof dataLayer !== 'undefined') {

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "cart_order_now",
                coupon: promo.value,
                ecommerce: checkoutData.value,
            });

        }

        if (config.public.IS_PROD && typeof fbq !== 'undefined') {
            fbq('trackCustom', 'cart_order_now', fbqData.value);
        }

        resolve(true);

    });

}

const sendEvents = function (data) {
    return sendGoogleEvent(data);
}

const promo = ref('');
const promoValid = ref(true);
const promoApplied = ref(false);
const hasPromo = computed(() => {
    return promo.value?.length > 0;
})
const hasDiscount = computed(() => {
    return discountTotal.value > 0;
})

const onPromoChange = function () {
    if (promo.value.trim() == '') {
        showPromoCode.value = false;
        promoApplied.value = false;
    }
}

const trackingData = computed(() => {
    const params = new URLSearchParams(window?.location.search || '');
    const AFF_TOKEN = params.get('via');

    if (AFF_TOKEN) {
        sessionStorage.setItem('rewardful-aff', AFF_TOKEN);
        rewardful('source', AFF_TOKEN);
    }



    return {
        utm_campaign: params.get('utm_campaign'),
        utm_medium: params.get('utm_medium'),
        promo: promo.value,
        utm_source: params.get('utm_source'),
        client_reference_id: params.get('client_reference_id'),
        via: AFF_TOKEN
    }
});

const fbqData = computed(() => {
    return {
        currency: 'USD',
        value: total.value,
        contents: [
            {
                id: config.public.STRIPE_PRODUCT,
                quantity: quantity.value,
                valueAnnual: plan.value === 'monthly' ? 12 * total.value : total.value,
                value: total.value,
                coupon: promo.value,
            },
        ],
        content_ids: [config.public.STRIPE_PRODUCT],
        content_name: "WYND Sentry Subscription",
        content_type: 'product',
    }
})

const checkoutData = computed(() => {
    return {
        currency: "USD",
        value: total.value,
        valueAnnual: plan.value === 'monthly' ? 12 * total.value : total.value,
        coupon: promo.value,
        items: [{
            item_id: config.public.STRIPE_PRODUCT,
            item_name: "WYND Sentry Subscription",
            item_category: plan.value,
            currency: "USD",
            discount: totalSavings.value,
            item_brand: "Wynd",
            price: itemPrice.value,
            quantity: quantity.value,
            coupon: promo.value,
        }]
    }
});

const setPlan = function (selected) {

    plan.value = selected;

    if (config.public.IS_PROD && typeof dataLayer !== 'undefined') {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: added_to_cart.value ? `cart_select_${selected}` : 'cart_view',
            ecommerce: checkoutData.value
        });
    }

    if (config.public.IS_PROD && typeof fbq !== 'undefined') {
        fbq('trackCustom', added_to_cart.value ? `cart_select_${selected}` : 'cart_view', fbqData.value);
    }

    added_to_cart.value = true;

}
const startCheckout = function (plan) {

    if (promoValid.value === false) {
        promo.value = '';
        promoApplied.value = false;
        showPromoCode.value = false;
    }

    let referral = null;

    if (typeof Rewardful !== 'undefined' && Rewardful?.referral) {
        referral = Rewardful.referral;
    }

    $fetch(`/.netlify/functions/checkout-${plan}`, {
        method: 'POST', body: qs.stringify({
            ...trackingData.value,
            qty: quantity.value,
            // device: device.value,
            referral,
            email: email.value
        })
    }).then((res) => {
        if (typeof res === 'string') res = JSON.parse(res);
        sendEvents(res).then(function () {
            console.log('Redirecting', res.session.url);
            window.location.assign(res.session.url);
        });
    }).catch((error) => {
        console.error('Error:', error);
        if (
            error.response && error.response._data?.includes('No such coupon') ||
            error.response && error.response._data?.includes('No such promotion code')
            ||
            error.response && error.response._data?.includes('This promotion code is not specified for use with this customer')
        ) {
            promoValid.value = false;
            promoApplied.value = true;
            showPromoCode.value = true;
            promoDetails.value = null;
        }
    });
}

const focusEmailField = function () {
    window.scrollTo({
        top: document.querySelector('.sentry-email').getBoundingClientRect().top + window.scrollY - 50,
        behavior: 'smooth'
    });

    document.querySelector('[type="email"]').focus();
}

const formatMoney = function (number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

const order = function () {
    if (!formValid.value) {
        if (!isEmailValid.value) {
            focusEmailField();
        }
        return;
    }

    switch (plan.value) {
        case 'monthly':
            startCheckout('monthly');
            break;
        case 'annual':
        default:
            startCheckout('annual');
            break;
    }
}

const onEmailUpdate = function () {
    if (config.public.IS_PROD && typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: "cart_input_email",
            email: email.value,
        });
    }

    if (config.public.IS_PROD && typeof fbq !== 'undefined') {
        fbq('trackCustom', 'cart_input_email', {
            email: email.value
        });
    }
}

onMounted(() => {
    if (window && !('sessionStorage' in window)) {
        window.sessionStorage = storage;
    }

    const params = new URLSearchParams(window?.location.search || '');
    const selected = params.get('plan');
    promo.value = params.get('prefilled_promo_code');

    if (promo.value) {
        showPromoCode.value = true;
        applyCoupon();
    }

    setPlan(selected ?? 'annual');

})
</script>
